import React from 'react';
import '../../styles/react-components/footer-fm.scss';
import FooterMenu from './footer/FooterMenu';
import FooterMenuMobile from './footer/FooterMenuMobile';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import auth from '../../auth/authenticate';

class Footer extends React.Component {
    render() {
        return (
            <>
                {this.props.isLoggedIn && !auth.isPassive() && (
                    <>
                        <footer className="footer">
                            <FooterMenu/>
                        </footer>
                        <FooterMenuMobile/>
                    </>
                )}
            </>
        );
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.auth.isLoggedIn,
    };
}

export default connect(mapStateToProps, matchDispatchToProps)(Footer);
