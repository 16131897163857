import { Typography } from "@rmwc/typography";
import React from "react";
import { FormattedMessage } from "react-intl";
import InvoiceStatus from "./InvoiceStatus";

const InvoiceHeader = ({ invoice, showStatus = true }) => {

    const { number: invoiceNumber, status, deadline } = invoice;

    return (
        <>
            <Typography
                use="headline3"
                className="mdc-typography mdc-theme--primary"
            >
                <FormattedMessage
                    id="Invoice.Title"
                    values={{
                        number: invoiceNumber
                    }}
                />
            </Typography>
            {showStatus && (
                <InvoiceStatus
                    showText
                    status={status}
                    deadline={deadline}
                    className="ml-15" />
            )}
        </>
    );
}

export default InvoiceHeader;