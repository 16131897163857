import { bindActionCreators } from 'redux';
import auth from './authenticate';
import { logout, refreshJwt } from "../actions/authActions";
import { postDeviceRegistration } from "../actions/pushNotificationActions";
import { updateIntl } from "react-intl-redux";
import language from "../lang/lang";
import { isMobile } from 'react-device-detect';
import { getDeviceRegistrationInfo } from '../helpers/pushNotificationHelper';
import { sendLoginStatusToAppContainerWithDelay, redirectToAppContainerSmsLogin, isNewerOrSameAppVersion } from "../helpers/appContainerHelper";

const AuthMiddleware = (store) => (next) => (action) => {

    refreshJWT(store);

    if (isUnAuthorized(action)) {
        callLogout(store);
        next(action);
    }
    else if (action.type) {
        switch (action.type) {
            case "LOG_IN_FULFILLED": {
                auth.logIn(action.payload);
                calloutLangChanged(store);
                registerMobileDeviceForPushNotifications(store);
                sendLoginStatusToAppContainerWithDelay(true);
                redirectToAppContainerIfSmsLogin(action.payload);
                next(action);
                break;
            }
            case "LOG_IN_REJECTED":
                sendLoginStatusToAppContainerWithDelay(false, 250);
                next(action);
                break;
            case "LOG_OUT_REJECTED": {
                if (auth.getTokenJwt()) {
                    auth.logOut();
                }
                next(action);
                break;
            }
            case "LOG_OUT_FULFILLED": {
                auth.logOut();
                next(action);
                break;
            }
            default: {
                next(action);
                break;
            }
        }
    } else {
        next(action);
    }
};

function isUnAuthorized(action) {
    return action.payload && action.payload.response && action.payload.response.status === 401;
}

function callLogout(store) {
    console.debug("unauhorized")
    let boundActionCreators = bindActionCreators({ logout: logout }, store.dispatch);
    boundActionCreators.logout();
}
// Once SSO has sent you lang, than accept it and change only after logging in, otherwise unmounting issues
function calloutLangChanged(store) {
    let boundActionCreators = bindActionCreators({ updateIntl }, store.dispatch);
    const currentLang = language.getLang();
    boundActionCreators.updateIntl({
        locale: currentLang,
        messages: language.getMessages(currentLang)
    });
}

function refreshJWT(store) {
    if (auth.isTokenRefreshable()) {
        auth.flagTokenRefresh();
        let boundActionCreators = bindActionCreators({ refreshJwt: refreshJwt }, store.dispatch);
        boundActionCreators.refreshJwt();
    }
}

function registerMobileDeviceForPushNotifications(store) {
    if (isMobile) {
        let boundActionCreators = bindActionCreators({ postDeviceRegistration: postDeviceRegistration }, store.dispatch)
        const payload = getDeviceRegistrationInfo();

        if (!!payload) {
            boundActionCreators.postDeviceRegistration(payload);
        }
    }
}

function redirectToAppContainerIfSmsLogin(data) {
    if (isMobile && data?.isSmsLogin) {
        const phoneNumber = auth.getUserData()?.phoneNumber;
        const appVersion = localStorage.getItem("appVersion") || "0.0.0";
        const isCorrectAppVersion = isNewerOrSameAppVersion("3.0.12", appVersion);

        if (phoneNumber && isCorrectAppVersion) {
            redirectToAppContainerSmsLogin(phoneNumber);
        }
    }
}

export default AuthMiddleware;