import React from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import CheckboxField from "../common/CheckboxField";
import { Electricity } from "../../const/contractType";
import ProductType from "../../const/productType";

const ContractTOS = ({ fieldName, isChecked, onChange, isGreen, contractType, productType, showBackDownText, disabled = false }) => {
    const intl = useIntl();
    const isUniversalProduct = productType === ProductType.UniversalPrice;
    const isUniversalDynamicProduct = productType === ProductType.UniversalDynamicPrice;

    let termsTextId = "Contract.Terms";
    if (isUniversalProduct) { termsTextId += ".Universal"; }
    if (isUniversalDynamicProduct) { termsTextId += ".UniversalDynamic" }
    if (isGreen) { termsTextId += ".Green"; }

    let acceptTermsTextId = "Contract.AcceptTerms";
    if (isUniversalProduct) { acceptTermsTextId += ".Universal"; }
    if (isUniversalDynamicProduct) { acceptTermsTextId += ".UniversalDynamic"; }

    const getTOSLink = () => {
        if (contractType === Electricity) {
            return intl.formatMessage({ id: "Contract.Terms.ElectricityTOSUrl" });
        }
        return intl.formatMessage({ id: "Contract.Terms.GasTOSUrl" });
    }

    return (
        <>
            <CheckboxField
                nativeControlId="tos-accept"
                field={fieldName}
                checked={isChecked}
                indeterminate={false}
                label={
                    <label htmlFor="tos-accept">
                        <FormattedMessage id={acceptTermsTextId} />
                    </label>
                }
                onChange={onChange}
                disabled={disabled}
            />
            <div
                className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent information__description medium">
                <FormattedMessage id={termsTextId}
                    values={{
                        TOSLink: <a
                            href={getTOSLink()} target="_blank" rel="noopener noreferrer">
                            <FormattedMessage id="Contract.Terms.Here" />
                        </a>,
                        priceLink: <a
                            href={intl.formatMessage({ id: "Contract.Terms.PriceList" })} target="_blank" rel="noopener noreferrer">
                            <FormattedMessage id="Contract.Terms.Here" />
                        </a>,
                        greenLink: <a
                            href={getTOSLink()} target="_blank" rel="noopener noreferrer">
                            <FormattedMessage id="Contract.Terms.Here" />
                        </a>,
                    }} />
            </div>
            {showBackDownText &&
                <div
                    className="mdc-text-field-covert-text mdc-text-field-helper-text--persistent information__description medium">
                    <FormattedMessage id="Contract.Terms.BackDown" />
                </div>}
        </>
    );
}

ContractTOS.defaultProps = {
    showBackDownText: true
}

export default ContractTOS;