import slideBg1 from "../../../images/wrapped_2024/slides/loyalty/Lojaalsus 1.png";
import slideBg1_x2 from "../../../images/wrapped_2024/slides/loyalty/Lojaalsus 1@2x.png";
import slideBg2 from "../../../images/wrapped_2024/slides/loyalty/Lojaalsus 2.png";
import slideBg2x2 from "../../../images/wrapped_2024/slides/loyalty/Lojaalsus 2@2x.png";
import slideBg3 from "../../../images/wrapped_2024/slides/loyalty/Lojaalsus 3.png";
import slideBg3x2 from "../../../images/wrapped_2024/slides/loyalty/Lojaalsus 3@2x.png";
import {FormattedHTMLMessage, useIntl} from "react-intl";
import React from "react";

export const Loyalty = ({index, data}) => {
    const intl = useIntl();
    const {slideBg, slideBg_x2, textId, value} = [
        {slideBg: slideBg1, slideBg_x2: slideBg1_x2, textId: "Wrapped2024.Loyalty.Horses", value: data.horses},
        {slideBg: slideBg2, slideBg_x2: slideBg2x2, textId: "Wrapped2024.Loyalty.FreeLunches", value: data.freeLunchesMil.toLocaleString(intl.locale)},
        {slideBg: slideBg3, slideBg_x2: slideBg3x2, textId: "Wrapped2024.Loyalty.Playgrounds", value: data.playgrounds}
    ][index];
    const backgroundOptions = {
        src: slideBg,
      srcSet: `${slideBg} 1x, ${slideBg_x2} 2x`,
    }
    return (
      <div className="wrapped__carousel-slide wrapped__loyalty">
          <div className='wrapped__loyalty__content'>
              <p><FormattedHTMLMessage id="Wrapped2024.Loyalty.Title" values={{savings: data.totalSavingsMil}}/></p>
              <h1><FormattedHTMLMessage id={textId} values={{value: value}}/></h1>
          </div>

          <img
              {...backgroundOptions}
              alt='slide-bg'
              className='wrapped__carousel-slide__background'
          />
      </div>
    )
}
